* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Nunito", sans-serif;
}

.app {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
h1 {
  color: white;
}
button {
  border: none;
  outline: none;
  width: 100%;
  padding: 15px 0;
  color: #fff;
  font-size: 16px;
  letter-spacing: 1px;
  background: rgb(160, 202, 88);
  cursor: pointer;
  border-radius: 10px;
}

.logo {
  height:2.5rem;
  margin: auto 0 auto 1.4rem;
  /* padding:0.2rem; */
}
h2 {
  padding-top: 1rem;
  color: black;
}
.hero {
  width: 100%
}

.app-container {
  margin-top: 4rem;
  margin-left: 10rem;
  padding:1rem;
  width: calc(100% - 10rem);
  height: 100%;
}
/* LOGIN */
.login {
  width: 100%;
  min-height: 100vh;
  padding: 0 20px;
  background: #e9e9e9;
  display: flex;
}

.login .loginContainer {
  border-radius: 10px;
  padding: 60px;
  margin: auto;
  width: 100%;
  max-width: 520px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background:rgba(82, 99, 113, 1);
}

.login .loginContainer label {
  color: white;
  margin: 14px 0;
  display: block;
  font-size: 22px;
  line-height: 1;
}

.login .loginContainer input {
  width: 100%;
  border: none;
  outline: none;
  font-size: 19px;
  padding: 10px;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  letter-spacing: 1px;
}

.login .loginContainer .btnContainer {
  width: 100%;
  padding: 24px 0;
}

.login .loginContainer .btnContainer p {
  margin: 14px 0 0 0;
  text-align: right;
  color: #fff;
}

.login .loginContainer .btnContainer p span {
  color: yellow;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-left: 5px;
  cursor: pointer;
  transition: all 400ms ease-in-out;
}

.login .loginContainer .btnContainer p span:hover {
  color: red;
}

.login .loginContainer .errorMsg {
  color: red;
  font-size: 16px;
}




/* NAV */
.nav {
  position: fixed;
  width: 100%;
  height: 4rem; 
  background:rgba(82, 99, 113, 1);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav button {
  width: 86px;
  padding: 8px 0;
  margin-right: 2rem;
  background: #3a2b58;
}
.nav-links {
  position: fixed;
  z-index: 1;
  top: 4rem;
  left: 0px;
  min-height: 100vh;

  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 10rem;
  background:rgba(82, 99, 113, 1);
  display: flex;
  justify-content: flex-start;

  flex-direction: column;
  list-style: none;
  color: white;
  font-weight: bold;
  font-size: large;
}
.nav-link-item-selected {
  background: rgb(160, 202, 88);
  border-radius: 6px;
}
.nav-links li {
  /* text-align: center; */
  padding: 4px 2px;
  margin-left: 0.5rem;
  /* margin: 0px 0rem; */
  cursor: pointer;
  text-decoration: none;
}



/* SERVICES */
.service-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: flex-start;
  font-size: large;
}
.service-list {
  position: relative;
  overflow-y:auto;
  height: 484px;
  padding: 1rem 2rem;
  background-color: white;
  box-shadow: 1px 0px 5px 1px rgba(94, 94, 94, 0.25);
  border-radius: 10px;
  max-width: 475px;
  display: flex;
  flex-direction: column;
  font-size: large;
}
.new-service-btn {
  position: sticky;
  top: 0px;
  left: 0px;
  margin: 0.5rem auto 0.5rem auto;
}

.service-list ul {
  list-style-type: none;
  position: relative;
}
.service-list ul li {
  padding: 8px 10px;
  margin: 8px 0px;
  cursor: pointer;
  font-size: medium;
}

.service-item-selected {
  color:white;
  margin: 8px 0px;
  background: rgb(89, 145, 203);
  border-radius: 5px;
}

.edit-service-container {
  position: relative;
  margin: 0 1rem;
  padding: 1rem 1rem 5rem 1rem;
  min-width: 300px;
  width: 65%;
  max-width: 580px;

  background-color: white;
  box-shadow: 1px 0px 5px 1px rgba(94, 94, 94, 0.25);
  border-radius: 10px;

}
.title-input:focus {
  outline: none;
}
.url-input:focus {
  outline: none;
}
#description-input:focus {
  outline: none;
}
.edit-service-container input, .edit-service-container label, .edit-service-container textarea {
  display: block;
  font-size: 1.2rem;
}
.edit-service-container input, .edit-service-container textarea {
  margin: 0.5rem auto;
  width: 100%;
  padding: 0.3rem;
  border-style: none;
  background: rgba(211, 211, 211, 0.288) ;
  border-radius: 5px;
}
.edit-service-container textarea {
  outline: none;
  resize:none;
  /* height:50%; */
}
.edit-service-container button {
  width: 100px;
}
.file-uploader  {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

}
#file-uploader-preview-image {
  width:3rem;
  height:3rem;
  /* padding: 1rem; */
  margin-right: 1rem;
  object-fit: cover;
}
.file-uploader button {
  width:140px;
}
.new-button {
  max-width: 12rem;
}




/* GALLERY */

.gallery {
  width: 100%;
}
.gallery h2 {
  text-align: center;
  width: 100%;
}
.gallery-category-list {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  justify-content: flex-start;
  flex-direction: row;
  list-style: none;
  margin-top: 1rem;
}
.gallery-category-list li {
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
  align-items: center;
  margin-right: 1rem;
  padding: 0.5rem 1rem;
}
.gallery-grid-container {
  /* overflow-y:auto; */
  /* height: 75vh; */
  margin-top: 1rem;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.gallery-grid-item {
  position: relative;
  background-color: white;
  border-radius: 10px;
  box-shadow: 1px 0px 5px 1px rgba(94, 94, 94, 0.25);
  margin: 1rem;
  padding: 1rem;
  width: 190px;
  height:240px;
}

.gallery-grid-item img{
  object-fit: cover;
  border-radius: 10px;
  width: 100%;
  height: 70px;
}

.button-container {
  position: absolute;
  bottom: 1rem;
  transform: translateX(-50%);
  left: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.button-container button {
  margin: 0 0.5rem;
  padding: 1rem;
}

.edit-btn {
  margin: 0 auto;
  display: block;
  position: absolute;
  bottom: 0.5rem;
  left: 25%;
  background:rgba(33, 155, 69, 0.596);
  padding: 0.5rem;
  margin-top: 0.2rem;
  width:2.5rem;
  transform: translateX(-25%);
  /* width:2.1rem; */
  /* height:2.1rem; */
}
.bottom-right-btn {
  position: absolute;
  bottom: 0.5rem;
  right: 25%;
  margin-top: 0.2rem;
  transform: translateX(25%);
}
.delete-btn {
  margin: 0 auto;
  display: block;
  background:rgba(162, 42, 44, 1);
  padding: 0.5rem;
  width:2.5rem;
  
  /* width:2.1rem; */
  /* height:2.1rem; */
}

#overlay {
  position: fixed; /* Sit on top of the page content */
  /* display: none; Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  margin: auto;
}
.overlay-popup {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  top: 30%;
  height: 450px;
  width: 50%;
  left: 50%;
  color:black;
  max-width: 400px;
  background:white;
  padding:1.3rem;
  padding-bottom: 4.5rem;
  border-radius: 10px;
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
}
#gallery-item-title {
  margin-top: 0.5rem;
  font-size: 1rem;
  border-style: none;
  border-bottom: 2px solid lightgray;
}
#gallery-item-title:focus {
  outline: none;
}
.select-gallery-filter {
  background: white;
  margin: 1rem 0;
}
.overlay-title {
  padding-top:1rem;
  display: flex;
  flex-direction: column;
}
.overlay-popup h2 {
  font-size: 1.5rem;
  padding: 0;
 }
 .overlay-popup label {
  font-size: 1rem;
 }
 
 .category-filter-item {
  background:lightgray;
  cursor: pointer;
  border-radius: 10px;
}
 .category-filter-item-active {
  border-radius: 10px;
  background:rgba(82, 99, 113, 1);
  color: white;
 }




 /* ZANIUMLJIVOSTI */
.overlay-title-label {
  padding:0;
  margin:0 0 1rem 0;
}
.overlay-textarea {
  margin: 1rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  height:100px;
}

 #fact-item-title {
  outline: none;
  padding:0.5rem;
  resize: none;
  margin-top: 0.5rem;
  font-size: 1rem;
  border-style: none;
  background: rgba(211, 211, 211, 0.288) ;
  border-radius: 10px;
  height: 100%;
 }

.facts-grid-container {

  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.facts-grid-item {
  position: relative;
  background-color: white;
  border-radius: 10px;
  box-shadow: 1px 0px 5px 1px rgba(94, 94, 94, 0.25);
  margin: 1rem;
  padding: 0.5rem;
  width:210px;
  height:410px;
}
#fact-item-description {
  padding: 0.2rem 0.5rem;
  height:110px;
  text-align: center;
  vertical-align: middle;
  font-size: 0.9rem;
  color: gray;
}
.facts-grid-item img{
  object-fit: cover;
  border-radius: 10px;
  width: 100%;
  height: 180px;
}
.service-subservice-item {
  display: flex;
  justify-content: flex-start;
}
.service-subservice-item input {
  margin: 0.5rem;
  margin-left: 0;
}
 
.promo-image-url-container button {
  width: 100px;
  margin: 0 1rem;
}
.promo-image-url-container input {
  width: 400px;
}

.promo-image-url-container label {
  margin-right: 1rem;
  height: 50px;
  line-height: 50px;

}
.promo-image-url-container {
  margin-top: 1rem;
  display: flex;
  height: 3rem;
}




 /* MEDIA QUERY */
 @media only screen and (max-width: 850px) {
  .new-service-btn {
    max-width: 50%;
    justify-self: flex-start;
    align-self: flex-start;
    margin: 0.5rem auto;
  }
  .nav button {
    width:70px;
    padding: 0.rem;
    margin-right: 0.5rem;
  }
  .overlay-popup {
    width:85%;
    height:350px;
  }

   .nav-links {
    position: absolute;
    justify-content: start;
    align-items: flex-end;
    flex-direction: row;
    top: 1rem;
    left: 0px;
    min-height: 2rem;
    padding-top: 0rem;
    padding-left: 0.5rem;
   }
   .nav-links li { 
     margin: 0 0.5rem;
     text-align: center;
   }
   .app-container {
     margin-left: 0;
     margin-right: 0;
     padding-left: 0.5rem;
     padding-right: 0.5rem;
   }
   .logo {
     display: none;
   }
   .facts-grid-container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 2rem;
  }
   .facts-grid-item {
    text-align: center;
    height:300px;
    width: 48%;
    margin-bottom: 0.5rem;
    margin-right: 1%;
    margin-left: 1%;
   }
   .gallery-grid-item {
     /* margin: auto; */
     text-align: center;
     height:240px;
     width: 40%;
     margin-bottom: 0.5rem;
     margin-right: 5%;
     margin-left: 5%;
   }
   .gallery-grid-container {
     margin-left: auto;
     margin-right: auto;
     padding-left: 0;
     padding-right: 0;
     align-items: flex-start;
     justify-content: flex-start;
     padding-bottom:1.5rem;
   }
   .service-container {
     width: 90%;
     flex-direction: column;
     padding: 0rem;
     padding-bottom: 2rem;
     margin: auto;
   }
   .service-list-container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
   }
   .service-list {
     width: 100%;
     height: 250px;
     margin: 2rem auto 0 auto;
     min-width: 300px;
     max-width: 600px;
   }
   .edit-service-container textarea {
    height:35%;
  }
   .edit-service-container {
    margin: 0 auto;
    max-width: 600px;
    width: 100%;
  }
 }